import axios from 'axios';

const instance = axios.create({
    baseURL: process.env.NEXT_PUBLIC_STRAPI_BASE_URL || 'http://localhost:1337'
});

const strapiInstance = axios.create({
    baseURL: process.env.NEXT_PUBLIC_STRAPI_API_URL || 'http://localhost:1337/api',
    headers: {
        Authorization: `Bearer ${process.env.NEXT_PUBLIC_STRAPI_API_KEY}`,
        'Content-Type': 'application/json',
        'Accept-Encoding': 'gzip,deflate,compress'
    }
});

export default instance;

export { strapiInstance as StrapiConnector };
