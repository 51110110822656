import React, { useEffect, useState } from 'react';
import { Disclosure } from '@headlessui/react';
import PropTypes from 'prop-types';
import { BiMenu, BiMenuAltRight } from 'react-icons/bi';
import { IoClose, IoCloseCircle, IoCloseOutline } from 'react-icons/io5';
import Link from 'next/link';
import { classNames } from '@utils/helpers';
import Image from 'next/image';
import { useRouter } from 'next/router';

const Navbar = ({ navigation }) => {
    const [navbarColor, setNavbarColor] = useState('bg-transparent');
    const [navbarHidden, setNavbarHidden] = useState(true);
    const { pathname } = useRouter();

    const updateNavbar = () => {
        if (window.scrollY > 100) {
            setNavbarColor('bg-white');
            setNavbarHidden(false);
        } else {
            setNavbarColor('bg-transparent');
            setNavbarHidden(true);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', updateNavbar);
        return () => window.removeEventListener('scroll', updateNavbar);
    }, []);

    return (
        <Disclosure
            as="nav"
            className={`${navbarColor} transition-colors duration-300 sticky top-0 z-10`}
        >
            {({ open }) => (
                <>
                    <div className="mx-auto px-2 sm:px-6 lg:px-8">
                        <div className="relative flex h-26 items-center justify-between">
                            <div className="absolute inset-y-0 left-0 flex items-center sm:hidden"></div>
                            <div className="items-center justify-center sm:items-stretch sm:justify-start">
                                <Link href={'/'} className="flex flex-shrink-0 items-center">
                                    <>
                                        <div className="block relative h-12 w-12 lg:hidden">
                                            <a href={'/'}>
                                                <Image
                                                    src="/assets/img/logo.png"
                                                    alt="Frostan Limited"
                                                    layout="fill"
                                                    objectFit="contain"
                                                />
                                            </a>
                                        </div>
                                        <div className="hidden relative h-24 w-24 lg:block">
                                            <a href={'/'}>
                                                <Image
                                                    src="/assets/img/logo.png"
                                                    alt="Frostan Limited"
                                                    layout="fill"
                                                    objectFit="contain"
                                                />
                                            </a>
                                        </div>
                                    </>
                                </Link>
                            </div>
                            <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                                <div className="hidden sm:ml-6 sm:block">
                                    <div className="flex space-x-4">
                                        {navigation.map((item) => (
                                            <a
                                                key={item.name}
                                                href={item.href}
                                                className={classNames(
                                                    item.href === pathname
                                                        ? 'bg-primary-500 text-white'
                                                        : navbarHidden
                                                        ? 'text-white hover:bg-primary-200 hover:text-gray-800'
                                                        : 'text-gray-800 hover:bg-primary-300 hover:text-gray-900',
                                                    'px-3 py-2 rounded-md text-sm font-medium'
                                                )}
                                                aria-current={item.current ? 'page' : undefined}
                                            >
                                                {item.name}
                                            </a>
                                        ))}
                                    </div>
                                </div>
                                <Disclosure.Button className="md:hidden rounded p-1 ml-2 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-primary-500 inline-flex rounded-md p-2">
                                    <span className="sr-only">Open main menu</span>
                                    {open ? (
                                        <div className={'block w-full'}>
                                            <Disclosure.Panel className="sm:hidden">
                                                <IoClose
                                                    className="block h-5 w-5 text-gray-100"
                                                    aria-hidden="true"
                                                />
                                                <div className="absolute -z-10 w-screen h-screen bg-primary-600 top-0 -right-2 text-center space-y-1 px-8 pt-16 pb-3">
                                                    {navigation.map((item) => (
                                                        <Disclosure.Button
                                                            key={item.name}
                                                            as="a"
                                                            href={item.href}
                                                            className={classNames(
                                                                item.current
                                                                    ? 'right-0 pr-5 bg-gray-900 text-white'
                                                                    : ' text-gray-300 hover:bg-gray-700 hover:text-white',
                                                                'block px-3 py-2 rounded-md text-base font-medium'
                                                            )}
                                                            aria-current={
                                                                item.current ? 'page' : undefined
                                                            }
                                                            style={{
                                                                fontSize: '15px',
                                                                lineHeight: '10px'
                                                            }}
                                                        >
                                                            {item.name}
                                                        </Disclosure.Button>
                                                    ))}
                                                </div>
                                            </Disclosure.Panel>
                                        </div>
                                    ) : (
                                        <BiMenu
                                            className="block h-6 w-6 text-primary-500"
                                            aria-hidden="true"
                                        />
                                    )}
                                </Disclosure.Button>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </Disclosure>
    );
};

Navbar.propTypes = {
    navigation: PropTypes.array
};

Navbar.defaultProps = {
    navigation: [
        { name: 'Dashboard', href: '#', current: true },
        { name: 'Team', href: '#', current: false },
        { name: 'Projects', href: '#', current: false },
        { name: 'Calendar', href: '#', current: false }
    ]
};

export default Navbar;
