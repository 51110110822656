import qs from 'qs';

const classNames = (...classes) => {
    return classes.filter(Boolean).join(' ');
};

/**
 * Helper to make GET request url for Strapi API endpoints
 * @param {string} path Path of the API route
 * @param {Object} params URL params object, will be stringified
 * @returns API url with query string parameters.
 */
const strapiUrl = (path, params = {}) => {
    const queryString = qs.stringify(params);
    return `${path}${queryString ? `?${queryString}` : ''}`;
};

const strapiMedia = (media) => {
    const { url } = media.data.attributes;
    return url.startsWith('/') ? getStrapiURL(url) : url;
};

const strapiDeepMedia = (media) => {
    const { url } = media.attributes;
    return url.startsWith('/') ? getStrapiURL(url) : url;
};

export function getStrapiURL(path = '') {
    return `${process.env.NEXT_PUBLIC_STRAPI_BASE_URL || 'http://localhost:1337'}${path}`;
}

export function getLocationURL(location, key = process.env.NEXT_PUBLIC_GOOGLE_MAPS_KEY) {
    return `https://www.google.com/maps/embed/v1/place?key=${key}&q=${encodeURI(location)}`;
}

export function splitArray(array) {
    let start = [];
    let end = [];

    for (let i = 0; i < array.length; i++) {
        i % 2 === 0 ? start.push(array[i]) : end.push(array[i]);
    }

    return [start, end];
}

export { classNames, strapiUrl, strapiMedia, strapiDeepMedia };
