let checkCurrentRoute = (route) => {
    if (typeof window !== 'undefined') {
        return window.location.pathname === route;
    }
};
const Routes = [
    { name: 'Home', href: '/', current: checkCurrentRoute('/') },
    { name: 'Our Services', href: '/services', current: checkCurrentRoute('/services') },
    { name: 'Our Story', href: '/story', current: checkCurrentRoute('/story') },
    { name: 'Our Impact', href: '/impact', current: checkCurrentRoute('/impact') },
    { name: 'Our Team', href: '/team', current: checkCurrentRoute('/team') },
    { name: 'Visit Us', href: '/contact', current: checkCurrentRoute('/contact') }
    // { name: 'Blog', href: '/blogs', current: checkCurrentRoute('/blogs') }
];

export { Routes };
