import '../styles/globals.css';
import MainLayout from '@layouts/Main';
import Head from 'next/head';
import { useEffect, useState } from 'react';
import { Hydrate, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { config } from '@config/ReactQuery';
import { StrapiContext } from '../Context/StrapiContext';
import App from 'next/app';
import { StrapiConnector } from '@config/Axios';
import { strapiMedia, strapiUrl } from '@utils/helpers';
import { useRouter } from 'next/router';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';

function MyApp({ Component, pageProps }) {
    const { global } = pageProps;
    const router = useRouter();
    const [queryClient] = useState(() => new QueryClient(config));

    useEffect(() => {
        const handleStart = (url) => {
            console.log(`Loading: ${url}`);
            NProgress.start();
        };

        const handleStop = () => {
            NProgress.done();
        };

        router.events.on('routeChangeStart', handleStart);
        router.events.on('routeChangeComplete', handleStop);
        router.events.on('routeChangeError', handleStop);

        return () => {
            router.events.off('routeChangeStart', handleStart);
            router.events.off('routeChangeComplete', handleStop);
            router.events.off('routeChangeError', handleStop);
        };
    }, [router]);

    return (
        <QueryClientProvider client={queryClient}>
            <Hydrate state={pageProps.dehydratedState}>
                <StrapiContext.Provider value={global.attributes}>
                    <MainLayout>
                        <Head>
                            <link
                                rel="shortcut icon"
                                href={strapiMedia(global.attributes.Favicon)}
                            />
                            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                            <title>{global.attributes?.SiteName ?? 'Strapi Missing'}</title>
                        </Head>
                        <Component {...pageProps} />
                    </MainLayout>
                </StrapiContext.Provider>
            </Hydrate>
        </QueryClientProvider>
    );
}

MyApp.getInitialProps = async (ctx) => {
    const appProps = await App.getInitialProps(ctx);

    const params = {
        populate: {
            Favicon: '*',
            Meta: {
                populate: '*'
            }
        }
    };

    const globalRes = await StrapiConnector.get(strapiUrl('/global', params));

    return { ...appProps, pageProps: { global: globalRes.data.data } };
};

export default MyApp;
