import { RiFacebookBoxFill, RiTwitterFill, RiInstagramFill } from 'react-icons/ri';
import PropTypes from 'prop-types';
import { useInView } from 'react-intersection-observer';
import { classNames } from '@utils/helpers';

const SocialMedia = ({ direction, spacing, iconColor, iconSize, once, triggerDelay, skip }) => {
    const { ref, inView } = useInView({
        skip: skip,
        triggerOnce: once,
        delay: triggerDelay
    });
    return (
        <div ref={ref} className={`flex ${direction} ${spacing}`}>
            <a
                href="https://www.instagram.com/frostan_tz/?hl=en"
                className={classNames(
                    'transition-all duration-200 ease-in translate-x-full blur flex justify-end',
                    inView ? 'opacity-100 transform-none blur-none' : 'opacity-0'
                )}
            >
                <RiInstagramFill className={`${iconColor} ${iconSize} hover:text-primary-500`} />
            </a>
            <a
                href="https://twitter.com/frostan_tz?lang=en"
                className={classNames(
                    'transition-all duration-500 ease-in translate-x-full blur delay-100 flex justify-end',
                    inView ? 'opacity-100 transform-none blur-none' : 'opacity-0'
                )}
            >
                <RiTwitterFill className={`${iconColor} ${iconSize} hover:text-primary-500`} />
            </a>
            <a
                href="https://m.facebook.com/people/Frostan-Tz/100016047932853/"
                className={classNames(
                    'transition-all duration-500 ease-in translate-x-full blur delay-[200ms] flex justify-end',
                    inView ? 'opacity-100 transform-none blur-none' : 'opacity-0'
                )}
            >
                <RiFacebookBoxFill className={`${iconColor} ${iconSize} hover:text-primary-500`} />
            </a>
            {/*<a*/}
            {/*    href="#"*/}
            {/*    className={classNames(*/}
            {/*        'transition-all duration-500 ease-in opacity-0 translate-x-full blur delay-[300ms] flex justify-end',*/}
            {/*        inView ? 'opacity-100 transform-none blur-none' : ''*/}
            {/*    )}*/}
            {/*>*/}
            {/*    <RiLinkedinBoxFill className={`${iconColor} ${iconSize} hover:text-primary-500`} />*/}
            {/*</a>*/}
        </div>
    );
};

SocialMedia.propTypes = {
    direction: PropTypes.string,
    spacing: PropTypes.string,
    iconColor: PropTypes.string,
    iconSize: PropTypes.string,
    once: PropTypes.bool,
    triggerDelay: PropTypes.number,
    skip: PropTypes.bool
};

SocialMedia.defaultProps = {
    direction: 'flex-col',
    spacing: 'space-y-5',
    iconColor: 'text-white',
    iconSize: 'h-8 w-8',
    once: true,
    triggerDelay: 1500,
    skip: false
};

export default SocialMedia;
