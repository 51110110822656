import Link from 'next/link';
import SocialMedia from '@components/Hero/SocialMedia';
import Image from 'next/image';
import { Routes } from '@utils/routes';
import { v4 } from 'uuid';

const Footer = () => {
    return (
        <footer className="p-4 bg-white sm:p-6 ">
            <hr className="mb-6 border-primary-500 sm:mx-auto lg:mb-8" />
            <div className="mx-auto max-w-screen-2xl">
                <div className="md:flex md:justify-between">
                    <div className="mb-6 md:mb-0 text-center w-20 md:w-32">
                        <Link href="/" passHref>
                            <a className="flex justify-center w-full relative h-10 md:h-24 mr-3">
                                <Image
                                    src="/assets/img/logo-lg.png"
                                    alt="Frostan Logo"
                                    layout={'fill'}
                                    objectFit={'contain'}
                                />
                            </a>
                        </Link>
                        <h2 className="mt-3 mb-6 text-sm font-bold text-gray-900 uppercase">
                            Follow Us
                        </h2>
                        <SocialMedia
                            direction={'flex-row'}
                            spacing={'space-x-5'}
                            iconColor={'text-gray-700'}
                            iconSize={'h-4 md:h-7 w-4 md:w-7'}
                        />
                    </div>
                    <div className="grid grid-cols-3 gap-6">
                        <div>
                            <h2 className="mb-6 text-xs md:text-sm font-semibold text-gray-900 uppercase">
                                Learn
                            </h2>
                            <ul className="text-gray-600 text-xs md:text-sm">
                                {Routes.slice(1, Routes.length - 1).map((route) => (
                                    <li className="mb-3" key={v4()}>
                                        <Link href={route.href} passHref>
                                            <a className="hover:underline">{route.name}</a>
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div>
                            <h2 className="mb-6 text-xs md:text-sm font-semibold text-gray-900 uppercase">
                                Shop
                            </h2>
                            <ul className="text-gray-600 text-xs md:text-sm">
                                <li className="mb-3">
                                    <a
                                        href="https://www.nyamnyam.co.tz/categories/1"
                                        target="_blank"
                                        className="hover:underline"
                                        referrerPolicy="no-referrer-when-downgrade"
                                        rel="noreferrer"
                                    >
                                        Beef
                                    </a>
                                </li>
                                <li className="mb-3">
                                    <a
                                        href="https://www.nyamnyam.co.tz/categories/2"
                                        target="_blank"
                                        className="hover:underline"
                                        referrerPolicy="no-referrer-when-downgrade"
                                        rel="noreferrer"
                                    >
                                        Chicken
                                    </a>
                                </li>
                                <li className="mb-3">
                                    <a
                                        href="https://www.nyamnyam.co.tz/categories/3"
                                        target="_blank"
                                        className="hover:underline"
                                        referrerPolicy="no-referrer-when-downgrade"
                                        rel="noreferrer"
                                    >
                                        Sausages
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="https://www.nyamnyam.co.tz/categories/4"
                                        target="_blank"
                                        className="hover:underline"
                                        referrerPolicy="no-referrer-when-downgrade"
                                        rel="noreferrer"
                                    >
                                        Lutosa French Fries
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <h2 className="mb-6 text-xs md:text-sm font-semibold text-gray-900 uppercase">
                                Connect
                            </h2>
                            <ul className="text-gray-600 text-xs md:text-sm">
                                <li className="mb-3">
                                    <Link href="/contact" passHref>
                                        <a className="hover:underline">Contact us</a>
                                    </Link>
                                </li>
                                <li className="mb-3">
                                    <Link href="/contact" passHref>
                                        <a className="hover:underline">Our shops</a>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <hr className="my-10 border-white sm:mx-auto lg:my-8" />
                <div className="flex sm:items-center gap-6 justify-between">
                    <span className="text-[9px] md:text-sm text-gray-500 sm:text-center">
                        Powered by&nbsp;
                        <a
                            href="https://www.bigbee.io"
                            className="hover:underline"
                            target="_blank"
                            referrerPolicy="no-referrer-when-downgrade"
                            rel="noreferrer"
                        >
                            Bigbee Technology Limited
                        </a>
                    </span>
                    <span className=" text-[9px] md:text-sm text-gray-500 sm:text-center">
                        &copy; {new Date().getFullYear()} Frostan Limited™. All Rights Reserved.
                    </span>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
