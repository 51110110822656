import React from 'react';
import Navbar from '@components/Navbar';
import Footer from '@components/Footer';
import { Routes } from '@utils/routes';

const MainLayout = ({ children }) => {
    return (
        <React.Fragment>
            <Navbar navigation={Routes} />
            {children}
            <Footer />
        </React.Fragment>
    );
};

export default MainLayout;
